import React, { Component } from 'react';
import { useSearchParams, useNavigate, SetURLSearchParams, useLocation } from 'react-router-dom';

import { buildGatewayUrl } from './client/xhr';
import Axios, { CancelTokenSource } from 'axios';
import Layout from './layout/Layout';
import { AuthenticatedUser } from './authentication/Domain';
import AuthMethodPicker from './authentication/AuthMethodPicker';
import {ExpiredSession, LoadingProblem} from './errors/ErrorPages';
import {Helmet} from 'react-helmet';
import Notifier from './notifications/Notifier';
import NotificationClient from './notifications/NotificationClient';
import { UserContext } from './UserContext';
import Toaster from "./commons/components/toast/Toaster";
import "react-datetime/css/react-datetime.css";
import './App.css';
import { STORAGE_TOKEN_NAME } from './commons/CommonConstants';
import authenticationService from './authentication/AuthenticationService';
import {IdleTimerComponent, IIdleTimer, withIdleTimer} from 'react-idle-timer'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

class IdleTimerBase extends IdleTimerComponent {
    render () {
      return <></>
    }
}

const IdleTimer = withIdleTimer<IIdleTimer>(IdleTimerBase);

enum AuthenticationState {
	INITIAL = 1,
	REQUEST_FAILED,
	NOT_AUTHENTICATED,
	AUTHENTICATED,
	EXPIRED
}

interface State {
  authenticated: AuthenticationState;
  user?: AuthenticatedUser;
  redirectParams?:URLSearchParams
}

interface Props {
    navigate: any;
    searchParams: [URLSearchParams, SetURLSearchParams];
    location: any;
}

class AppClass extends Component<Props,State> {
	sessionChecker : any;
	sessionTimer : any;
	activityRecorded : boolean;
	sessionCheckerCancelationToken : CancelTokenSource = Axios.CancelToken.source();
	notificationClient : NotificationClient;
    navigate : any;
    searchParams :URLSearchParams;
    setSearchParams : SetURLSearchParams;
    location: any;

	constructor(props:Props) {
		super(props);
		this.state = {
			authenticated: AuthenticationState.INITIAL,
			user: undefined,
            redirectParams: undefined
		};
		this.sessionChecker = null;
		this.sessionTimer = null;
		this.activityRecorded = false;
		this.notificationClient = new NotificationClient();
		this.onSessionTimerTimeout = this.onSessionTimerTimeout.bind(this);
		this.onSessionCheckerTick = this.onSessionCheckerTick.bind(this);
		this.onUserInteraction = this.onUserInteraction.bind(this);
		this.logout = this.logout.bind(this);
        this.navigate = this.props.navigate;
        this.searchParams = this.props.searchParams[0];
        this.setSearchParams = this.props.searchParams[1];
        this.location = this.props.location;
	}

	componentDidMount() {

        if (this.searchParams.get("_authorization")) {
            localStorage.setItem("WEBEDI_AUTH", this.searchParams.get("_authorization")!);
            let newParams = this.searchParams;
            newParams.delete("_authorization");
            this.setState({redirectParams:newParams});
        }

        if (this.searchParams.get("logout_successful")) {
            localStorage.removeItem("WEBEDI_AUTH");
            let newParams = this.searchParams;
            newParams.delete("logout_successful");
            this.setState({redirectParams:newParams});
        }
		authenticationService.getSession().then(session => {
			this.setState({authenticated: AuthenticationState.AUTHENTICATED, user: session.user},() => this.activityRecorded = true);
		})
		.catch(error => {
			if (error.response && error.response.status === 401) {
				console.log("App bootstrapping: HTTP 401 arrived", error.response);
				this.setState({authenticated: AuthenticationState.NOT_AUTHENTICATED});
				return;
			}
			console.error("App bootstrapping: Error arrived", error);
			this.setState({authenticated: AuthenticationState.REQUEST_FAILED});
		});
	}

	componentDidUpdate(prevProps : Props, prevState : State) {
		if (prevState.authenticated !== this.state.authenticated) { // only on authentication state change
			if (this.state.authenticated === AuthenticationState.AUTHENTICATED) { // for transition to AUTHENTICATED
				console.log("Transition to " + AuthenticationState[this.state.authenticated] + " state, setting up the sessionChecker.");
				this.sessionChecker = setInterval(this.onSessionCheckerTick, 60000);
				this.notificationClient.open();
			} else {  // for other transitions
				console.log("Transition to " + AuthenticationState[this.state.authenticated] + " state, destroying the sessionChecker.");
				this.sessionChecker = clearInterval(this.sessionChecker);
				this.sessionTimer = clearTimeout(this.sessionTimer);
				this.notificationClient.close();
			}
		}
        if (prevState.redirectParams !== this.state.redirectParams) {
            this.setSearchParams(this.state.redirectParams,{replace:true});
        }
	}

	onSessionCheckerTick() {
		if (this.activityRecorded) {
			authenticationService.getSession(true, this.sessionCheckerCancelationToken.token)
			.then(session => {
				this.sessionTimer = clearTimeout(this.sessionTimer);
				this.activityRecorded = false;
				this.sessionTimer = setTimeout(this.onSessionTimerTimeout, session.sessionInfo.secondsRemain * 1000);
			}).catch(error => {
				console.log("Session checker got error:", error);
				// no error handling as another tick of the sessionChecker causes new request in a short period of time
			});
		}
	}

	onSessionTimerTimeout() {
		authenticationService.getSession()
			.then(response => {
				let remainingSeconds = response.sessionInfo.secondsRemain;
				this.sessionTimer = clearTimeout(this.sessionTimer);
				this.sessionTimer = setTimeout(this.onSessionTimerTimeout, (remainingSeconds ? remainingSeconds : 1) * 1000);
			})
			.catch(error => {
				if (error.response && error.response.status === 401) {
					console.log("Setting authentication to expired");
					this.setState({authenticated: AuthenticationState.EXPIRED});
				} else {
					// retry in 5 seconds when failure
					this.sessionTimer = setTimeout(this.onSessionTimerTimeout, 5000);
				}
			});
	}

	logout() {
		// prevent ticks of timers
		this.sessionChecker = clearInterval(this.sessionChecker);
		this.sessionTimer = clearTimeout(this.sessionTimer);
		// cancel ongoing session request(s) (if any)
		this.sessionCheckerCancelationToken.cancel();

		const token = localStorage.getItem(STORAGE_TOKEN_NAME);
		const landingURL = window.location.origin.toString() + "/?logout_successful=true";
		if (!token) {
			window.location.replace(landingURL);
			return;
		}

		let params = [["landingURL", landingURL], ["access_token", token]];
		let logoutURL = `/authentication/end-session?${new URLSearchParams(params).toString()}`;
		window.location.replace(buildGatewayUrl(logoutURL));
	}

	onUserInteraction(e? : Event, timer?:IIdleTimer) {
		if (!e) {return}
		if (e.type === "mousedown" || e.type === "keydown" || e.type === "wheel") {
			this.activityRecorded = true;
		}
	}

	content() {

		switch (this.state.authenticated) {
			case AuthenticationState.INITIAL:
				return (<></>);
			case AuthenticationState.REQUEST_FAILED:
				return (<LoadingProblem />);
			case AuthenticationState.NOT_AUTHENTICATED:
				return (<AuthMethodPicker />);
			case AuthenticationState.AUTHENTICATED:
				return (<><IdleTimer onAction={this.onUserInteraction} /><DndProvider backend={HTML5Backend}><Layout user={this.state.user!} logoutFn={this.logout}/></DndProvider></>);
			case AuthenticationState.EXPIRED:
				return (<ExpiredSession />);
		}
	}

	render() {
		let location = window.location.hostname
		return <>
				<Helmet>
					<link rel="stylesheet"  type="text/css" href={"/css/default.css"} />
					<link rel="stylesheet"  type="text/css" href={"/css/"+location+".css"} />
					<link rel="stylesheet"  type="text/css" href={"/css/editel-webedi.css"} />
                    <script src={"/math.js"} type="text/javascript" />
				</Helmet>
				<Toaster />
				<Notifier />
				<UserContext.Provider value={this.state.user}>
					{this.content()}
				</UserContext.Provider>
			</>
	}
}

export default function App() {
    const navigate = useNavigate();
    const searchParams = useSearchParams();
    const location = useLocation();
    return <AppClass navigate={navigate} location={location} searchParams={searchParams}/>;
}
