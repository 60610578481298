import xhr from "../client/xhr";
import {
    AccountWithUsers,
    AccountWithUsersPatchRequest,
    CreateAccountWithUsersRequest,
    IAccount,
    AccountWithUsersPatchResponse, IEditelAddress
} from "./Domain";
import CommonUtils from "../commons/CommonUtils";
import CacheMap from "../commons/cache/CacheMap";
import CacheUtils from "../commons/cache/CacheUtils";

export default class AccountService {

    private static instance: AccountService;

    private addressMap: CacheMap<IEditelAddress>;

    private addressLoadingMap: Map<string,{loading:boolean,waiting:Array<{resolve:any,reject:any}>}>;

    private constructor() {
        // sizes for caches of objects
        this.addressMap = new CacheMap(200);
        this.addressLoadingMap = new Map();
    }

    public static getInstance(): AccountService {
        if (!AccountService.instance) {
            AccountService.instance = new AccountService();
        }
        return AccountService.instance;
    }

    public retrieveAddressForAccount(gln: string, accountId: string, organization : string, tenantId : string, includePublic : boolean) : Promise<IEditelAddress> {
        return CacheUtils.retrieveObject<IEditelAddress>(gln, this.addressLoadingMap, this.addressMap, "address", `/gateway/masterdata/organization/${organization}/tenant/${tenantId}/accounts/${accountId}/addresses?numbers=${gln}&_includePublic=${includePublic}`);
    }

    public resetAddressInCache(gln: string) : void {
        if (this.addressMap.get(gln)) {
            this.addressMap.delete(gln)
        }
    }

    public getAccount(id : string, organization : string, tenantId : string) : Promise<IAccount> {
        return xhr.get(`/gateway/masterdata/organization/${organization}/tenant/${tenantId}/accounts/${id}`).then((response : any) => response.data);
    }

    public findAccountById(id : string, organization?: string) : Promise<IAccount> {
        return xhr.get(`/gateway/masterdata/accounts?id=${id}` + (organization ? "&organization=" + organization : "")).then((response : any) => response.data && response.data.length > 0 ? response.data[0] : undefined);
    }

    public saveAccount(id : string, organization : string, tenantId : string, changes: any) : Promise<IAccount> {
        return xhr.patch(`/gateway/masterdata/organization/${organization}/tenant/${tenantId}/accounts/${id}`, CommonUtils.trimObjectFields(changes)).then((response : any) => (new AccountWithUsersPatchResponse(response.data)).accountResponse);
    }

    public addAccount(organization : string, tenantId : string, request: CreateAccountWithUsersRequest) : Promise<IAccount> {
        return xhr.post(`/gateway/masterdata/organization/${organization}/tenant/${tenantId}/accounts`, request).then((response : any) => response.data);
    }

    public retrieveAccountsWithUsers(organization?: string) : Promise<AccountWithUsers[]> {
        return xhr.get("/gateway/masterdata/accounts?_sortBy=gln&_includeUsers=true" + (organization ? "&organization=" + organization : ""))
        .then((response : any) => Object.values(response.data).map((a) => new AccountWithUsers(a)));
    }

    public retrieveAccountWithUsers(id : string, organization?: string) : Promise<AccountWithUsers> {
        return xhr.get("/gateway/masterdata/accounts?id=" + id+"&_sortBy=gln&_includeUsers=true" + (organization ? "&organization=" + organization : ""))
            .then((response : any) => response.data && response.data.length > 0 ? response.data[0] : undefined);
    }

    public deleteAccount(id : string, organization : string, tenantId : string) : Promise<IAccount> {
        return xhr.delete(`/gateway/masterdata/organization/${organization}/tenant/${tenantId}/accounts/${id}`);
    }

    public patchAccountWithUsers(request: AccountWithUsersPatchRequest, id: string, organization : string, tenantId : string) : Promise<AccountWithUsersPatchResponse> {
        return xhr.patch(`/gateway/masterdata/organization/${organization}/tenant/${tenantId}/accounts/${id}`, CommonUtils.trimObjectFields(request)).then((response : any) => new AccountWithUsersPatchResponse(response.data));
    }

    public retrieveAddressesForAccount(accountId: string, organization : string, tenantId : string) : Promise<IEditelAddress[]> {
        return xhr.get(`/gateway/masterdata/organization/${organization}/tenant/${tenantId}/accounts/${accountId}/addresses`).then((response : any) => Object.values(response.data));//.map((a) => new AdditionalAddress(a)));
    }

    public updateAddress(accountId: string, organization : string, tenantId : string, request: any, id: string) : Promise<IEditelAddress> {
        return xhr.patch(`/gateway/masterdata/organization/${organization}/tenant/${tenantId}/accounts/${accountId}/addresses/${id}`, CommonUtils.trimObjectFields(request)).then((response : any) => {this.resetAddressInCache(response.data.gln); return response.data});
    }

    public updatePrimaryGln(accountId: string, organization : string, tenantId : string, addressId: string) : Promise<IEditelAddress> {
        return xhr.patch(`/gateway/masterdata/organization/${organization}/tenant/${tenantId}/accounts/${accountId}/addresses/${addressId}/newprimary`).then((response : any) => response.data);
    }

    public deleteAddress(accountId: string, organization : string, tenantId : string, id: string) : Promise<IEditelAddress> {
        return xhr.delete(`/gateway/masterdata/organization/${organization}/tenant/${tenantId}/accounts/${accountId}/addresses/${id}`).then((response : any) => {this.resetAddressInCache(response.data.gln); return response.data});
    }
    public createAddress(accountId: string, organization : string, tenantId : string, request: IEditelAddress) : Promise<IEditelAddress> {
        return xhr.post(`/gateway/masterdata/organization/${organization}/tenant/${tenantId}/accounts/${accountId}/addresses`, CommonUtils.trimObjectFields(request)).then((response : any) => response.data);
    }

}

export const accountService = AccountService.getInstance();
