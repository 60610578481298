import {ITableEntity} from "../commons/components/table/DataTableComponent";

export class FlowTemplate implements ITableEntity{
    id: string;
    accountId: string;
    organization: string;
    templateName: string;
    templateVersion: number;
    steps: Array<FlowStepTemplate>;
    flowTemplateGroupIds?: string[];
    partnerGln: PartnerGln;
    sortFields: {[id: string]: string};
    matches: MatchDto;
    state: FlowTemplateState;
    modifiedTimestamp?: string;
    modifiedBy?:string;

    constructor(data : any) {
        this.id = data.id;
        this.accountId = data.accountId;
        this.organization = data.organization;
        this.templateName = data.templateName;
        this.templateVersion = data.templateVersion;
        this.steps = data.steps.map((s : any) => new FlowStepTemplate(s));
        this.flowTemplateGroupIds = data.flowTemplateGroupIds;
        this.partnerGln = new PartnerGln(data.partnerGln);
        this.sortFields = data.sortFields;
        this.matches = data.matches;
        this.state = data.state;
        this.modifiedTimestamp = data.modifiedTimestamp;
        this.modifiedBy = data.modifiedBy;
    }

    public getPartnerGlnHumanReadable(): string | undefined {
        return (this.partnerGln) ? this.partnerGln.getPartnerGlnHumanReadable(): undefined;
    }
}

export class PartnerGln {
    value: string;
    type: "PREFIX"|"VALUE"

    constructor(data : any) {
        this.value = (data && data.value) ? data.value: "";
        this.type = (data && data.type) ? data.type: "VALUE";
    }

    public getPartnerGlnHumanReadable(): string | undefined {
        if(this.type === "PREFIX") {
            return this.value + "*";
        }
        return this.value;
    }
}

export class FlowStepTemplate {
    name:string;
    types:Array<string>;
    documentTemplateName:string;
    options:Array<FlowStepOptionTemplate>;
    exposedFields:Array<IExposedField>;
    identifier:string;
    translation?:object;
    recipient: string;

    constructor(data : any) {
        this.name = data.name;
        this.types = data.types;
        this.documentTemplateName = data.documentTemplateName;
        this.options = (data.options || []).map((o : any) => new FlowStepOptionTemplate(o));
        this.exposedFields = data.exposedFields;
        this.identifier = data.identifier;
        this.translation = data.translation;
        this.recipient = data.recipient;
    }
}

export class FlowStepOptionTemplate {
    option:string;
    dependsOn: Array<string>;
    sources: Array<string>;
    conversion?: string;
    conditions?: Array<FlowStepOptionConditionTemplate>;
    template?: string;
    combinedProcessing: boolean;

    constructor(data : any) {
        this.option = data.option;
        this.dependsOn = data.dependsOn ? (data.dependsOn as any[]).map(String) : [];
        this.sources = data.sources ? (data.sources as any[]).map(String) : [];
        this.conditions = data.conditions;
        this.conversion = data.conversion;
        this.template = data.template;
        this.combinedProcessing = data.combinedProcessing;
    }
}

export class FlowStepOptionConditionTemplate {
    expression: string;
    imports: {flowDocuments: {identifier: string, states: string[] | null, keys: string[]}[]};

    constructor(data : any) {
        this.expression = data.expression;
        this.imports = data.imports;
    }
}

export interface IExposedField {
    key: string;
    definition: string|null;
    showInQuickInfo: boolean;
}

export type FlowTemplateState = "ACTIVE" | "INACTIVE" | "DEPRECATED";

export class FlowTemplatePatchRequest {
    partner?: PartnerGlnRequest;
    organization?: string;
    state?: FlowTemplateState
    flowTemplateGroupIds?: string[];
    sortFieldDeliveryPlaceGln0?: string;
    steps?: {[key : string]: {[key: string]: any, addedOptions?: FlowStepOptionTemplate[]}};
    addedSteps?: FlowStepTemplate[];
    stepsOrder?: number[];

    constructor(partner?: PartnerGlnRequest, organization?: string, state?: FlowTemplateState, flowTemplateGroupIds?: string[], sortFieldDeliveryPlaceGln0?: string, steps?: {[key : string]: {[key: string]: any, addedOptions?: FlowStepOptionTemplate[]}}, addedSteps? : FlowStepTemplate[], stepsOrder?: number[]) {
        this.partner = partner;
        this.organization = organization;
        this.sortFieldDeliveryPlaceGln0 = sortFieldDeliveryPlaceGln0;
        this.flowTemplateGroupIds = flowTemplateGroupIds;
        this.steps = steps;
        this.addedSteps = addedSteps;
        this.state = state;
        this.stepsOrder = stepsOrder;
    }
}

export class FlowTemplateCreateRequest {
    templateName: string;
    templateVersion:number=1;
    flowTemplateGroupIds?: string[];
    organization: string;
    partnerGln: PartnerGlnRequest;
    steps?: Array<[number, {[key: string] : any}]>;
    sortFields?:{[key: string]: string};

    constructor(templateName:string,partnerGln: PartnerGlnRequest, organization: string, flowTemplateGroupIds?: string[], steps?: Array<any>,sortFields?:{[key: string]: string}) {
        this.templateName = templateName;
        this.flowTemplateGroupIds = flowTemplateGroupIds;
        this.organization = organization;
        this.partnerGln = partnerGln;
        this.steps = steps;
        this.sortFields = sortFields;
    }
}

export interface MatchDto {
    sender:string ;
    recipient:string ;
    msgType:string ;
}

export class PartnerGlnRequest {
    type: "VALUE" | "PREFIX"| "REGEX";
    value: string;

    constructor(type: "VALUE" | "PREFIX"| "REGEX", value: string) {
        this.type = type;
        this.value = value;
    }
}

export class MatchDto {
    sender: string;
    recipient: string;
    msgType: string;

    constructor(sender: string, recipient: string, msgType: string) {
        this.sender = sender;
        this.recipient = recipient;
        this.msgType = msgType;
    }
}

export class KeyValue {
    key: string;
    value: any;

    constructor(key: string, value: any) {
        this.key = key;
        this.value = value;
    }
}

export class DocumentTemplateDataDefinition {
}

export class DocumentTemplateDataValidation {
}

export class DocumentTemplateDataInit {
    data:Array<KeyValue>;
    constructor(data: Array<KeyValue>) {
        this.data = data;
    }
}

export class DocumentTemplateData {
    html:string;
    definitions:Array<DocumentTemplateDataDefinition>;
    validations?:Array<DocumentTemplateDataValidation>;
    init?:DocumentTemplateDataInit;
    i18n?:any;
    imports?:any;
    printing?:string;
    constructor(data : any) {
        this.html = data.html;
        this.definitions = data.definitions;
        this.validations = data.validations;
        this.init = data.init;
        this.i18n = data.i18n;
        this.imports = data.imports;
        this.printing = data.printing;
    }
}

export class DocumentTemplate {
    id: string;
    templateName: string;
    templateVersion: number;
    organization: string;
    showDebugInfo?:boolean;
    from?: string;
    productIDPath?: string;
    data: DocumentTemplateData;
    state:FlowTemplateState;
    modifiedTimestamp?: string;
    modifiedBy?:string;
    constructor(data : any) {
        this.id = data.id;
        this.templateName = data.templateName;
        this.showDebugInfo = data.showDebugInfo;
        this.templateVersion = data.templateVersion;
        this.organization = data.organization;
        this.from = data.from;
        this.state = data.state;
        this.data = data.data;
        this.modifiedTimestamp = data.modifiedTimestamp;
        this.modifiedBy = data.modifiedBy;
	this.productIDPath = data.productIDPath;
    }
}

export class DocumentTemplateMinimal {
    id: string;
    templateName: string;
    templateVersion: number;
    state:FlowTemplateState;
    constructor(data : any) {
        this.id = data.id;
        this.templateName = data.templateName;
        this.templateVersion = data.templateVersion;
        this.state = data.state;
    }
}

export class DocumentTemplateCreateRequest {
    templateName: string;
    templateVersion:number=1;
    from?: string;
    state?: string;
    showDebugInfo?: boolean;
    productIDPath?: string;
    data: DocumentTemplateData;
    constructor(templateName:string, templateVersion:number, data : any, state?: string, from?:string, showDebugInfo?:boolean,productIDPath?:string) {
        this.templateName = templateName;
        this.templateVersion = templateVersion;
        this.state = state;
        this.from = from;
        this.showDebugInfo = showDebugInfo;
        this.productIDPath = productIDPath;
        this.data = data;
    }
}

export class DocumentTemplatePatchRequest {
    organization?: string;
    state?: string;
    from?: string | null;
    showDebugInfo?: boolean;
    productIDPath?: string;
    data?: DocumentTemplateData;

    constructor(organization?: string, state?: string, from?: string | null, showDebugInfo?: boolean,productIDPath?: string, data?: DocumentTemplateData ) {
        this.organization = organization;
        this.from = from;
        this.showDebugInfo = showDebugInfo;
        this.state = state;
        this.productIDPath = productIDPath;
        this.data = data;
    }
}

export class DocumentTemplateVersionPatchRequest {
    from?: string | null;
    showDebugInfo?: boolean;
    productIDPath?: string;
    data?: DocumentTemplateData;

    constructor(from?: string | null,  showDebugInfo?: boolean, productIDPath?:string, data?: DocumentTemplateData) {
        this.from = from;
        this.showDebugInfo = showDebugInfo;
        this.productIDPath = productIDPath;
        this.data = data;
    }
}

export interface ITemplateGroup {
    id: string;
    name: string;
    organization: string;
    accountIds: string[];
}

export interface ITemplateGroupWithTemplates {
    id: string;
    name: string;
    organization: string;
    templateNames?: string[]
}

export class FlowTemplateGroupCreateRequest {
    name: string;
    accountId?: string;

    constructor(name: string, accountId?: string) {
        this.name = name;
        this.accountId = accountId;
    }
}

export class FlowTemplateGroupPatchRequest {
    organization?: string;
    addAccountId?: string;
    removeAccountId?: string;

    constructor(organization?: string, addAccountId?: string, removeAccountId?: string) {
        this.organization = organization;
        this.addAccountId = addAccountId;
        this.removeAccountId = removeAccountId;
    }
}

export const OPTION_NEW_VALUE = "NEW";
export const OPTION_UPDATE_VALUE = "UPDATE";
export const OPTION_REDO_VALUE = "REDO";
export const OPTION_ACCUMULATE_VALUE = "ACCUMULATE";

export const STEP_ARRIVAL_VALUE = "ARRIVAL";
export const STEP_CREATE_VALUE = "CREATE";
