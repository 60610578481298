export const PUBSUB_SSE_EVENTS_TOPIC = "$events";
export const ROLE_SYSTEM_ADMINISTRATOR = 0xFFFFFFFF;
export const ROLE_ORGANIZATION_ADMINISTRATOR = 0x0FFFFFFF;
export const ROLE_ACCOUNT_ADMINISTRATOR = 0x000FFFFF;
export const ROLE_ACCOUNT_ADMINISTRATOR_LIGHT = 0x0000FFFF;

export const PERMISSIONS_CREATE_PRODUCT_BUTTON = ROLE_ACCOUNT_ADMINISTRATOR;
export const PERMISSIONS_DELETE_PRODUCT_BUTTON = ROLE_ACCOUNT_ADMINISTRATOR;

export const MASS_ACTION_DELETE = 'delete';
// eslint-disable-next-line
export const EMAIL_REGEXP = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

// eslint-disable-next-line
export const PHONENUMBER_REGEXP = /^[a-zA-Z0-9 .\-$:&_'/@%()*\+,;#]*$/i;

// eslint-disable-next-line
export const GLN_REGEXP = /^[a-zA-Z0-9 .\-$:&_'/@%()*#]{0,256}$/i;


export const STORAGE_TOKEN_NAME = "WEBEDI_AUTH";