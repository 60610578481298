import React, { Component } from 'react';
import { Navbar, Dropdown } from 'react-bootstrap';
import { AuthenticatedUser } from '../../authentication/Domain';
import {Trans} from 'react-i18next';
import {FaBars} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import LanguageMenu from './LanguageMenu';
import {
    ROLE_SYSTEM_ADMINISTRATOR,
    ROLE_ORGANIZATION_ADMINISTRATOR,
    ROLE_ACCOUNT_ADMINISTRATOR
} from "../../commons/CommonConstants";
import './OptionsDropdown.css';

interface Props {
    user: AuthenticatedUser;
    logoutFn: () => void;
}

interface State {
}

export class MenuItem {
    location : string;
    transKey : string;
    key : string;
    faIcon : null|string;

    constructor( location : string, transKey : string, faIcon : null|string) {
        this.transKey = transKey;
        this.location = location;
        this.key = location.replace('/','');

        this.faIcon = faIcon;
    }
}

class OptionsDropdown extends Component<Props, State> {

    getMenuItems() {
        const items = [];

        if (this.props.user.permissions === ROLE_SYSTEM_ADMINISTRATOR || this.props.user.permissions === ROLE_ORGANIZATION_ADMINISTRATOR ) {
            items.push(new MenuItem( '/tenants/','Options.Administration.DropDown.Tenants', null));
            items.push(new MenuItem( '/accounts/','Options.Administration.DropDown.Accounts', null));
            items.push(new MenuItem( '/template-groups/','Options.Administration.DropDown.TemplateGroups', null));
            items.push(new MenuItem( '/flow-templates/','Options.Administration.DropDown.FlowTemplates', null));
            items.push(new MenuItem( '/document-templates/','Options.Administration.DropDown.DocumentTemplates', null));

            items.push(null)
        }
        if (this.props.user.permissions !== 0) {
            items.push(new MenuItem( '/products/','Options.DropDown.Products', null));
            items.push(new MenuItem( '/company/','Options.DropDown.Company', null));

            if (this.props.user.permissions >= ROLE_ACCOUNT_ADMINISTRATOR) {
                items.push(new MenuItem( '/advanced-settings/','Options.Administration.DropDown.AdvancedSettings', null));
            }
            items.push(null)
        }
        return items
    }

    getAuthBackLink() : string | undefined {
        let urls = (window as any).settings?.authenticationUserUrls ? (window as any).settings?.authenticationUserUrls : process.env.AUTHENTICATION_USER_URLS;
        if (urls) {
            let url = urls[this.props.user.authMethod];
            if (url) {
                url = url.replace("%USER%",this.props.user.authName);
                url = url.replace("%REDIRECT%",window.location);
                return url;
            }
        }
        return undefined;
    }

    render() {
        return (
            <Navbar.Collapse className="justify-content-end menu-nav">
                <LanguageMenu />

                <Dropdown alignRight>
                    <Dropdown.Toggle variant="link" size="lg" className="navbar-brand navbar-menu maintextcolor" id="dropdown-menu">
                        <span className="d-none d-md-inline-block"><Trans i18nKey="Options.Configuration"/>&nbsp;</span>
                        <span><FaBars /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Header><Trans i18nKey="Home.SignedIn" />&nbsp;<strong>{this.getAuthBackLink() ? <a href={this.getAuthBackLink()} target="_blank" rel="noreferrer">{this.props.user.authName}</a> : this.props.user.authName}</strong></Dropdown.Header>
                        <Dropdown.Divider key={"menu_header_divider"} />

                        {this.getMenuItems().map((item, idx) => {return (item === null) ?
                            <Dropdown.Divider key={"menu_divider_"+idx} />
                            :
                            <Dropdown.Item key={'menu_item_'+item.location.replace('/','')} as={Link} to={item.location}><Trans i18nKey={item.transKey} /></Dropdown.Item>})}

                        <Dropdown.Item key={'menu_item_logout'}  onClick={this.props.logoutFn}><Trans i18nKey="Home.Menu.Logout"/></Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Navbar.Collapse>);
    }

}
export default OptionsDropdown;
