import React, { Component } from 'react';
import { Popover, Toast, OverlayTrigger } from 'react-bootstrap';
import { AuthenticatedUser } from '../../authentication/Domain';
import {Trans} from 'react-i18next';

interface Props {
	user: AuthenticatedUser;
	className?: string;
}

interface State {
}

export default class Notifications extends Component<Props, State> {

	render() {
		let popover = (
			<Popover id="popover-basic">
			  <Popover.Title as="h3"><Trans i18nKey="Notifications.Title"/></Popover.Title>
				<Popover.Content>
				  <Toast>
					<Toast.Header>
					  <strong className="mr-auto"><span role="img" aria-label="message">✉</span></strong>
					  <small><Trans i18nKey="Notifications.MinsAgo" values={{mins:'5'}}></Trans></small>
					</Toast.Header>
					<Toast.Body><Trans i18nKey="Notifications.OrdersReceived" values={{count:'2'}}></Trans></Toast.Body>
				  </Toast>
				  <Toast>
					<Toast.Header>
					  <strong className="mr-auto"><span role="img" aria-label="error">⚡</span></strong>
					  <small><Trans i18nKey="Notifications.MinsAgo" values={{mins:'11'}}></Trans></small>
					</Toast.Header>
					<Toast.Body><Trans i18nKey="Notifications.MsgCouldNotBeSent"></Trans></Toast.Body>
				  </Toast>
				  <Toast>
					<Toast.Header>
					  <strong className="mr-auto"><span role="img" aria-label="success">✓</span></strong>
					  <small><Trans i18nKey="Notifications.MinsAgo" values={{mins:'16'}}></Trans></small>
					</Toast.Header>
					<Toast.Body><Trans i18nKey="Notifications.OrdRspSent"></Trans></Toast.Body>
				  </Toast>
			  </Popover.Content>
			</Popover>
		  );

		const PushNotificationPopover = () => (
			<OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
				<></>
			</OverlayTrigger>
		);

		let authorized = this.props.user.authorized;

		return (authorized ? <PushNotificationPopover /> : null);
	}
}