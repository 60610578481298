import React from "react";
import {Slide, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './Toaster.css';

const Toaster = () => {
    return <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        transition={Slide}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={"light"}
    />;
};
export default Toaster;