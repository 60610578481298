import { Document, IKeyValue, ImportedData } from "../DocumentDomain";
import { ITemplateSchema, IValidationItem } from "../../documenttemplate/Domain";

export interface ValidationError {
	validation : IValidationItem,
	fields? : string[];
	messages: {[k: string]: string};
}

export default class DocumentValidator {

	private document : Document;
	private template : ITemplateSchema;
	private importedPairs: IKeyValue[];
	private library : string;
	private definitions : Set<string>;

	constructor(document: Document, template: ITemplateSchema, imports: ImportedData, library : string) {
		this.document = document;
		this.template = template;
		this.importedPairs = imports.data;
		this.library = library;
		this.definitions = new Set(template.definitions.map(d => d.name));
	}

	public validate(data : {[k: string]: any}, send : boolean = false, validations?: IValidationItem[]) : ValidationError[] {
		// create dataMap
		let dataMap = this.merge(data);
		return (validations || this.template.validations.filter(v => (send || !v.sendOnly) && v.type === 'JS'))
			.flatMap(val => this._validate(val, dataMap));
	}

    private _validate = (val: IValidationItem, dataMap: { [k: string]: IKeyValue }): ValidationError[] => {
        let result = this.evaluate(val.expression, dataMap);
        if (!val.fields) {
            return result ? [] : [{validation: val, messages: val.messages}];
        }
        let d = this.getDimensions(val.fields);
        if (d === 0) {
            return result ? [] : [{validation: val, fields: val.fields, messages: val.messages}];
        }
        let failedFields = val.fields.flatMap(field => this.getValidationResultFailedFields(result, field))
        return failedFields.length ? [{validation: val, fields: failedFields, messages: val.messages}] : [];
    }

    private getValidationResultFailedFields(result: [], fieldName: string) {
        const flattened: number[][] = [];

        function flattenErrorsPath(data: [], outputArray: any[], keys: number[] | undefined) {
            data.forEach((element: [] | boolean, key) => {
                const pathKeys = keys ?? [];
                if (Array.isArray(element)) {
                    pathKeys.push(key);
                    flattenErrorsPath(element, outputArray, pathKeys);
                } else {
                    if (!element) {
                        outputArray.push([...pathKeys, key]);
                    }
                }
            });
        }

        flattenErrorsPath(result, flattened, undefined);
        const resultArray: string[] = [];
        flattened.forEach(pathArr => {
            let fieldNameCurrent = fieldName;
            pathArr.forEach(i => fieldNameCurrent = fieldNameCurrent.replace("[]", "[" + i + "]"));
            resultArray.push(fieldNameCurrent);
        });
        return resultArray;
    }

	private merge(data : {[k: string]: any}) : {[k: string]: IKeyValue} {
        let dataMap : {[k: string]: IKeyValue} = {};
		[...this.document.data, ...this.importedPairs]
			.flatMap(kv => this.definitions.has(kv.key.replace(/\[[0-9]+\]/g, "[]")) ? (kv.initialValue === undefined ? [] : [{key: kv.key, value: undefined, initialValue: kv.initialValue} as IKeyValue]) : [kv])
			.forEach(kv => dataMap[kv.key] = kv);

		Object.entries(data).forEach(([k, v]) => {
			if (dataMap[k] !== undefined) {
				dataMap[k].value = v;
			} else {
				dataMap[k] = {key: k, value: v} as IKeyValue;
			}
		})
		return dataMap;
	}

	private evaluate(_expression : string, _dataMap : {[k: string]: IKeyValue}) : any {
		// _dataMap variable is propagated to the eval context
		// eslint-disable-next-line
		return eval(this.library + "\n\n" + _expression);
	}

	private getDimensions(fields : string[]) : number {
		return Math.max(...(fields.map(f => (f.match(/\[\]/g) || []).length)));
	}
}
