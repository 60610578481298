import React from "react";
import {toast, ToastOptions} from "react-toastify";

export type AppearanceTypes = 'error' | 'info' | 'success' | 'warning';

const classMap = {
    'error': 'danger',
    'info': 'info',
    'success': 'success',
    'warning': 'warning',
    'default': 'secondary'
}

export interface Options {
    appearance?: AppearanceTypes;
    autoDismiss?: boolean|number;
    id?: string;
    onOpen?:<T = {}>(props: T) => void;
    [key: string]: any;
}

const convertOptions = (options?:Options, type?:AppearanceTypes): ToastOptions => {
    const defaultOptions: ToastOptions = {
        position: "top-center",
    };
    const toastOption = {...defaultOptions}
    toastOption.type = type || options?.appearance
    toastOption.onOpen = options?.onOpen
    toastOption.toastId = options?.id ? options?.id : undefined
    toastOption.autoClose = options?.autoDismiss === true || options?.autoDismiss === undefined ? 5000 : options?.autoDismiss
    toastOption.className = ' small py-0 alert-' + classMap[toastOption.type || 'default']
    return toastOption
}

export const addToast = ( message: string|React.ReactNode, options?:Options):string => {
    const toastOption = convertOptions(options)
    return toast(message, toastOption) + '';
};


export const addToastSuccess = ( message: string|React.ReactNode, options?:Options) => {
    const toastOption = convertOptions(options, 'success')
    toast.success(message, toastOption);
};

export const addToastError = ( message: string|React.ReactNode, options?:Options) => {
    const toastOption = convertOptions(options, 'error')
    toast.error(message, toastOption);
};

export const addToastInfo = ( message: string|React.ReactNode, options?:Options) => {
    const toastOption = convertOptions(options, 'info')
    toast.info(message, toastOption);
};

export const addToastWarning = ( message: string|React.ReactNode, options?:Options) => {
    const toastOption = convertOptions(options, 'warning')
    toast.warning(message, toastOption);
};

export const removeToast = ( id: number | string) => {
    toast.dismiss(id);
};

